import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.greenLight};
  transform: skewX(-20deg);
  border-radius: 20px;
  padding: 1.875rem 6.5rem;

  @media (max-width: 1060px) {
    transform: none;
  }

  @media (max-width: 900px) {
    padding: 1.87rem 1rem;
  }
`;

export const Unskew = styled.div`
  transform: skewX(20deg);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1060px) {
    transform: none;
  }
`;

export const Separator = styled.span`
  background-color: rgba(255, 255, 255, 0.5);
  transform: skewX(-20deg);
  align-self: stretch;
  width: 1px;
  display: block;

  @media (max-width: 604px) {
    display: none;
  }
`;

export const Title = styled.h6`
  color: ${({ theme }) => theme.green};
  font-family: "FuturaBoldItalic";
  font-size: 1.875rem;
  margin-right: 2rem;

  @media (max-width: 900px) {
    margin-left: 2.3rem;
    width: 100%;
    text-align: center;
  }
`;

export const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @media (max-width: 900px) {
    padding: 0;
    width: 100%;
    justify-content: space-around;
  }
`;

export const CultureItemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 1.435rem;
`;

export const CultureItemLabel = styled.h6`
  color: #fff;
  font-size: 1.125rem;
  font-family: "FuturaBook";
`;

export const CultureIcon = styled.img`
  margin-right: 0.6rem;
`;
