import React from "react";

import {
  Container,
  Title,
  Unskew,
  List,
  CultureItemWrapper,
  Separator,
  CultureItemLabel,
  CultureIcon,
} from "./styles";

import CornIconSrc from "../../img/corn.svg";
import PastureIconSrc from "../../img/pasture.svg";
import GrainIconSrc from "../../img/grain.svg";
import SoyIconSrc from "../../img/soy.svg";

function CultureRow() {
  return (
    <Container>
      <Unskew>
        <Title>Culturas</Title>
        <List>
          <CultureItemWrapper>
            <CultureIcon src={CornIconSrc} />
            <CultureItemLabel>Milho</CultureItemLabel>
          </CultureItemWrapper>
          <Separator />
          <CultureItemWrapper>
            <CultureIcon src={SoyIconSrc} />
            <CultureItemLabel>Soja</CultureItemLabel>
          </CultureItemWrapper>
          <Separator />
          <CultureItemWrapper>
            <CultureIcon src={GrainIconSrc} />
            <CultureItemLabel>Trigo</CultureItemLabel>
          </CultureItemWrapper>
          <Separator />
          <CultureItemWrapper>
            <CultureIcon src={PastureIconSrc} />
            <CultureItemLabel>Pastagem</CultureItemLabel>
          </CultureItemWrapper>
        </List>
      </Unskew>
    </Container>
  );
}

export default CultureRow;
