import styled from "styled-components";

import HeroBackgroundImageSrc from "../../img/hero-background.png";

export const LoadingWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: green;
  z-index: 100;
`;

export const IntroWrapper = styled.div.attrs({
  // backgroundImage: HeroBackgroundImageSrc,
})`
  width: 100%;

  background-size: cover;
  background-position: center;
  background-color: #9bc310;
  background-image: url(${HeroBackgroundImageSrc});
  justify-content: center;
  display: flex;
`;

export const IntroHero = styled.h2`
  max-width: 570px;
  margin-top: 11.875rem;
  margin-bottom: 11.875rem;
  align-items: center;

  /* margin: 0 auto; */
  @media (max-width: 650px) {
    max-width: 80%;
  }
`;

export const SectionTitle = styled.h2`
  text-align: center;

  color: ${(props) => props.theme.green};
  font-size: 3.5rem;
  font-family: "FuturaBoldItalic";
  margin-bottom: 1rem;
`;

export const IntroDescription = styled.p`
  text-align: center;
  color: #ffffffaa;
  font-size: 1.125rem;
  font-family: "FuturaBook";
  margin-bottom: 3rem;
`;

export const VideoCTAWrapper = styled.div`
  padding: 0 3rem;
`;

export const WhyManaSection = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;
`;

export const WhyMana = styled.div`
  max-width: 44rem;
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  column-gap: 1.3rem;
  margin-top: 4.625rem;
  margin-bottom: 4.725rem;

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    padding: 0 2rem;
  }
`;

export const WhyManaTitle = styled.h3`
  color: ${(props) => props.theme.green};
  font-family: "FuturaBoldItalic";
  font-size: 2.25rem;

  @media (max-width: 750px) {
    margin-bottom: 1rem;
  }
`;

export const WhyManaParagraph = styled.p`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
  font-family: "FuturaBook";
`;

export const ValueSection = styled.section`
  padding-top: 6.75rem;
  padding-bottom: 10.1875rem;
  background-color: ${(props) => props.theme.green};
`;

export const ValueSectionTitle = styled(SectionTitle)`
  color: ${(props) => props.theme.greenLight};
  text-align: center;
`;

export const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 960px;
  margin: 4.875rem auto 0 auto;
  grid-column-gap: 2rem;
  grid-row-gap: 3.125rem;

  @media (max-width: 1052px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 2rem 0 3rem;
  }

  @media (max-width: 610px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const SolutionsSection = styled.section`
  position: relative;
  padding-top: 7.375rem;

  background-color: ${(props) => props.theme.greenLight};
`;

export const SolutionsSectionTitle = styled(SectionTitle)`
  color: #fff;
  text-align: left;
  max-width: 300px;
  @media (max-width: 900px) {
    text-align: center;
    max-width: 100%;
  }
`;

export const SolutionSlideWrapper = styled.div`
  margin-bottom: 10rem;
  /* max-width: 75%; */

  position: relative;
  margin-left: 15%;
  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

export const SolutionsSectionMenu = styled.div`
  position: absolute;
  top: -2rem;
  left: -2px;
  height: 120%;
  padding-right: 4rem;
  padding-top: 0rem;
  background: linear-gradient(
    80deg,
    #afca0b,
    #afca0b,
    #afca0baa,
    #cccccc00,
    #cccccc00
  );

  @media (max-width: 900px) {
    background: none;
    height: auto;
    padding-right: 0;
  }
`;

export const SolutionsSectionMenuItems = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-top: 4rem;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const SolutionsSectionFooter = styled.section`
  height: 10rem;

  background: linear-gradient(
      180deg,
      ${(props) => props.theme.green}66 0%,
      ${(props) => props.theme.greenLight}11 100%
    )
    0% 0% no-repeat;
  opacity: 0.3;
`;

export const CultureWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  max-width: 1000px;
  width: 100%;
  z-index: 1;
  transform: translate3d(-50%, -50%, 0);

  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

export const SupplierSection = styled.section`
  background-color: ${({ theme }) => theme.grey};
  padding: 7.9375rem 0;
`;

export const SupplierSectionTitle = styled(SectionTitle)`
  color: ${(props) => props.theme.greyLight};
  text-align: right;
  font-size: 2.25rem;
`;

export const SupplierSectionGrid = styled.div`
  display: grid;
  column-gap: 6.75rem;
  grid-template-columns: minmax(0, 200px) 1fr;
  max-width: 1120px;
  margin: 0 auto;

  @media (max-width: 1250px) {
    max-width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 950px) {
    grid-template-columns: 1fr;

    ${SupplierSectionTitle} {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
`;

export const BrandsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  column-gap: 2rem;
  align-items: center;

  @media (max-width: 1250px) {
    margin: 0 auto;
  }

  @media (min-width: 482px) {
    grid-template-areas:
      "sojaLabel sojaLabel sojaLabel"
      "sojaBrand1 sojaBrand2 sojaBrand3"
      "milhoLabel defesaLabel defesaLabel"
      "milhoBrand defesa1 defesa2";
    grid-template-rows: [row] 0 [row] 6rem [row] 0 [row] 6rem;
  }

  @media (max-width: 482px) {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }
`;

export const SupplierLabel = styled.h6`
  color: #fff;
  font-size: 0.625rem;
  font-family: "FuturaMedium";

  @media (max-width: 482px) {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;

export const BrandIcon = styled.img`
  max-width: 100%;
  height: auto;
  align-self: center;

  @media (max-width: 482px) {
    margin-bottom: 1rem;
  }
`;

export const CurriculumSection = styled.section`
  background-color: ${({ theme }) => theme.white};
  padding: 11rem 0;
`;



export const CurriculumSectionGrid = styled.div`
  display: grid;
  column-gap: 6.75rem;
  grid-template-columns: minmax(0, 200px) 1fr;
  max-width: 1120px;
  margin: 0 auto;

  @media (max-width: 1250px) {
    max-width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 950px) {
    grid-template-columns: 1fr;

    // ${CurriculumSectionTitle} {
    //   text-align: center;
    //   margin-bottom: 2rem;
    // }
  }
`;

export const CurriculumSectionTitle = styled(SectionTitle)`
  color: ${(props) => props.theme.green};
  text-align: right;
  font-size: 2.25rem;
`;

export const MapSection = styled.section`
  padding-top: 2rem;
  background-color: ${({ theme }) => theme.grey};
`;

export const DiagonalClip = styled.div`
  transform: skewX(30deg);
  transform-origin: bottom left;
  overflow: hidden;
  border-radius: 0 30px 0px 0;
  position: relative;
  width: 97%;
  height: 420px;

  @media (max-width: 1170px) {
    transform: skewX(0deg);
    border-radius: 0;
    width: 100%;
  }
`;

export const ClipMap = styled.div`
  transform: skewX(-30deg);
  transform-origin: bottom left;
  position: absolute;
  left: 0;
  top: 0;
  width: 120%;
  height: 100%;

  @media (max-width: 1170px) {
    transform: skewX(0deg);
    width: 100%;
  }
`;

export const AddressMapIconWrapper = styled.div`
  position: absolute;
  left: -60px;
  bottom: -60px;
  z-index: 1000;

  @media (max-width: 1120px) {
    left: -120px;
  }

  @media (max-width: 1038px) {
    display: none;
  }
`;

export const HamburguerMenuWrapper = styled.div`
  position: absolute;
  top: 1.3rem;
  right: 1rem;
  display: none;
  z-index: 2200;
  @media (max-width: 750px) {
    display: block;
  }
`;
