import React from "react";
import styled from "styled-components";
// import { Container } from './styles';

const Anchor = styled.a`
  display: inline-block;
  cursor: pointer;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : props.theme.green)};
  background-color: ${(props) =>
    props.filled ? props.color ?? props.theme.green : "transparent"};
  border-radius: 12px;
  transform: skewX(-20deg);
  padding: 1.125rem 2rem;
  font-family: "FuturaBoldItalic";
  color: ${(props) => props.textColor ?? props.theme.green};
  font-size: 0.875rem;
  transition: color 0.8s, border-color 0.8s, background-color 0.8s;
`;

const AnchorContent = styled.div`
  transform: skewX(20deg);
`;

function Button({
  children,
  color = null,
  borderColor = null,
  filled = true,
  textColor = null,
  ...props
}) {
  return (
    <Anchor
      color={color}
      borderColor={borderColor ?? color}
      filled={filled}
      textColor={textColor ?? (filled ? "#fff" : color)}
      {...props}
    >
      <AnchorContent>{children}</AnchorContent>
    </Anchor>
  );
}

export default Button;
