import styled from "styled-components";
import AddressAdorn from "../../img/address-adorn.svg";
import LogoColorSrc from "../../img/logo-color.svg";

export const Container = styled.div`
  position: relative;

  /* background-color: #fff; */
`;

export const Logo = styled.img.attrs({ src: LogoColorSrc })`
  margin-bottom: 2rem;
`;

export const Title = styled.h6`
  font-family: "FuturaBoldItalic";
  color: ${({ theme }) => theme.grey};
  margin-bottom: 0.825rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  max-width: 180px;
  transform: translate3d(-26px, 0, 0);
`;

export const Description = styled.p`
  font-family: "FuturaBook";
  color: #000;
  opacity: 0.5;
  font-size: 14px;
`;

export const Adorn = styled.img.attrs({ src: AddressAdorn })`
  position: absolute;
  top: 10px;
  left: -40px;
`;

export const Background = styled.div`
  width: 600px;

  background-color: #fff;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;

  clip-path: url("#clip-address");
`;

export const MobileBackground = styled.div`
  background-color: #fff;
  padding: 1rem 2rem 2rem 2rem;
  display: none;
  @media (max-width: 1038px) {
    display: block;
  }
`;
