import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import "@glidejs/glide/dist/css/glide.core.min.css";
// import "@glidejs/glide/dist/css/glide.theme.min.css";
import Glide from "@glidejs/glide";

import {
  BackgroundImage,
  Overlay,
  OverlayGradient,
  Background,
  SliderContainer,
  Description,
  SlideLabelMobile,
} from "./styles";

function SolutionSlide({ imgSrc, description, label }) {
  return (
    <Background>
      <BackgroundImage src={imgSrc} />
      <Overlay />
      <OverlayGradient />

      <Description>
        <SlideLabelMobile>{label}</SlideLabelMobile>
        {description}
      </Description>
    </Background>
  );
}

function SolutionSlider({ items, onChange = () => {} }, sliderRef) {
  const ref = useRef(null);
  const glideRef = useRef(null);
  useEffect(() => {
    // let glide = null;
    if (ref.current) {
      const glideInstance = new Glide(".glide", {
        type: "carousel",
        startAt: 0,
        focusAt: "center",
        perView: 3,
        breakpoints: {
          900: {
            perView: 2,
          },
          520: {
            perView: 1,
          },
        },
      });

      glideInstance.on(["mount.after", "run"], () => {
        onChange(glideInstance.index);
      });

      glideRef.current = glideInstance.mount();
    }

    return () => {
      if (glideRef.current) glideRef.current.destroy();
    };
  }, [ref.current]);

  useImperativeHandle(
    // Ref that comes from forwardRef
    sliderRef,

    // We tell React to expose the return value
    // to the parent component using ref
    () => ({
      goToIndex(index = 0) {
        glideRef.current.go(`=${index}`);
      },
    }),

    // Dependencies like useEffect's second param
    []
  );
  return (
    <SliderContainer>
      <div className="glide" ref={ref}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {items.map(({ label, description, src }) => {
              return (
                <li key={label} className="glide__slide">
                  <SolutionSlide
                    label={label}
                    imgSrc={src}
                    description={description}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <svg style={{ width: 0, height: 0 }} className="svg">
        <clipPath
          id="clip-path"
          width="0"
          height="0"
          clipPathUnits="objectBoundingBox"
        >
          <path d="M0.737,0 H0.424 A0.094,0.103,0,0,0,0.338,0.063 L0,0.938 A0.04,-0.060,0,0,0,0.040,1 H0.731 A0.086,0.094,0,0,0,0.81,0.943 L0.989,0.478 A0.099,0.109,0,0,0,0.985,0.384 L0.826,0.058 A0.101,0.111,0,0,0,0.737,0"></path>
        </clipPath>
      </svg>
    </SliderContainer>
  );
}

export default forwardRef(SolutionSlider);
