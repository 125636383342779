import React, { Form } from "react";

import {
    Container,
    ContainerForm,
    CurriculumInput,
    CurriculumButton,
    CurriculumText,
    CurriculumBorder,
    CurriculumTextBold
} from "./styles";

function CurriculumForm() {
    return (
        <Container>
            <form name="curriculumv2" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit="submit">
                <ContainerForm>
                    <CurriculumInput type="hidden" name="form-name" value="curriculumv2" />
                    <CurriculumBorder>
                        <CurriculumInput type="text" name="nome" id="nome" placeholder="Preencha aqui seu nome completo" required />
                    </CurriculumBorder>
                    <CurriculumBorder>
                        <CurriculumInput type="email" name="email" id="email" placeholder="Preencha aqui seu e-mail" required />
                    </CurriculumBorder>
                    <CurriculumBorder>
                        <CurriculumInput type="text" name="telefone" id="telefone" placeholder="Preencha aqui seu telefone" required />
                    </CurriculumBorder>
                    <CurriculumBorder>
                        <CurriculumInput type="text" name="setor" id="setor" placeholder="Para qual setor/cargo você gostaria?" required />
                    </CurriculumBorder>
                    <CurriculumBorder>
                        <CurriculumInput type="file" name="curriculo" id="curriculo" required accept="application/pdf,application/msword,application/vnd.oasis.opendocument.text,application/rtf" />
                    </CurriculumBorder>
                    <CurriculumText>
                        <input required type="checkbox" />O <CurriculumTextBold>candidato</CurriculumTextBold> ao enviar seu currículo, <CurriculumTextBold>declara</CurriculumTextBold> expressamente seu consentimento, tendo plena ciência dos direitos e obrigações deste instrumento, concordando que seus dados pessoais sejam tratados para a finalidade única e exclusiva de realizar ações relacionadas ao recebimento do currículo, sua avaliação e seleção, para eventual recrutamento para participação em processos de admissão para compor o quadro de funcionários da empresa.
                    </CurriculumText>
                    <CurriculumButton type="submit" value="Enviar currículo" />
                </ContainerForm>
            </form>
        </Container>
    );
}

export default CurriculumForm;