import React from "react";
import styled from "styled-components";

import ManaLogo from "../img/logo.svg";

const FullPage = styled.div`
  background-color: ${({ theme }) => theme.green};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  transition: opacity 0.65s;

  @media (max-width: 670px) {
    flex-flow: column wrap;
  }
`;
const LoaderWrapper = styled.div`
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

// import { Container } from './styles';

function Loader() {
  const [visible, setVisible] = React.useState(true);
  const [opacity, setOpacity] = React.useState(1);
  React.useEffect(() => {
    if (visible) {
      const interval = setInterval(() => {
        if (document.querySelector(".wf-active")) {
          setOpacity(0);
          setTimeout(() => {
            setVisible(false);
          }, 700);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [visible]);

  if (!visible) return null;
  return (
    <FullPage style={{ opacity }}>
      <img src={ManaLogo} style={{ marginRight: "2rem", height: "4rem" }} />
      <LoaderWrapper style={{ marginTop: "2rem" }}>
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </LoaderWrapper>
    </FullPage>
  );
}

export default Loader;
