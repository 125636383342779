import React, { useState } from "react";

import CortevaImgSrc from "../../img/marcas/corteva.svg";
import CotrijalImgSrc from "../../img/marcas/cotrijal.svg";
import MaximusImgSrc from "../../img/marcas/maximus.svg";
import MorganImgSrc from "../../img/marcas/morgan.svg";
import NideraImgSrc from "../../img/marcas/nidera.svg";
import PioneerImgSrc from "../../img/marcas/pioneer.svg";

import PecuariaImgSrc from "../../img/solucoes/gado.png";
import LogisticaImgSrc from "../../img/solucoes/logistica.png";
import EquipeImgSrc from "../../img/solucoes/equipe.png";
import ProtecaoImgSrc from "../../img/solucoes/protecao.png";
import SementeImgSrc from "../../img/solucoes/sementes.png";

import Navbar, { Logo, Links } from "../Navbar";
import Button from "../Button";
import VideoCTA from "../VideoCTA";
import ValueItem from "../ValueItem";
import CultureRow from "../CultureRow";
import SolutionSlide from "../SolutionSlide";
import Map from "../Map";
import AddressInfo, { AddressInfoMobile } from "../AddressInfo";
import Hamburguer from "../Hamburguer";
import Modal from "../Modal";
import Social from "../Social";
import Loader from "../Loader";
import {
  IntroWrapper,
  IntroHero,
  SectionTitle,
  IntroDescription,
  WhyMana,
  WhyManaSection,
  WhyManaTitle,
  WhyManaParagraph,
  ValueSection,
  ValueSectionTitle,
  ValuesGrid,
  SolutionsSection,
  SolutionsSectionMenu,
  SolutionsSectionTitle,
  SolutionsSectionFooter,
  SolutionsSectionMenuItems,
  CultureWrap,
  SupplierSection,
  SupplierSectionTitle,
  SupplierSectionGrid,
  BrandsGrid,
  SupplierLabel,
  BrandIcon,
  MapSection,
  ClipMap,
  DiagonalClip,
  SolutionSlideWrapper,
  VideoCTAWrapper,
  AddressMapIconWrapper,
  HamburguerMenuWrapper,
  CurriculumSection,
  CurriculumSectionGrid,
  CurriculumSectionTitle,
  // LoadingWrapper,
} from "./styles";

import colors from "../../config/colors";
import CurriculumForm from "../CurriculumForm";

const solutionsData = [
  {
    label: "LOGÍSTICA",
    description:
      "Desenvolvemos sistema de logística focado em atender com agilidade as demandas de nossos clientes.",
    src: LogisticaImgSrc,
  },
  {
    label: "EQUIPE ESTRATÉGICA",
    description:
      "Contamos com uma equipe de profissionais treinados e em constante capacitação para proporcionar acompanhamento de todo o processo de forma significativa e com conhecimento apropriado.",
    src: EquipeImgSrc,
  },
  {
    label: "SEMENTES",
    description:
      "Trabalhamos com uma diversificada linha de sementes, elegemos marcas sólidas e que combinam genética avançada e tecnologia de ponta, para entregar máximos padrões de qualidade e garantia de resultados.",
    src: SementeImgSrc,
  },
  {
    label: "PECUÁRIA",
    description:
      "Dispomos de lonas de alta qualidade, inoculantes para silagem e sementes de forrageiras.",
    src: PecuariaImgSrc,
  },
  {
    label: "PROTEÇÃO DE CULTIVOS",
    description:
      "Disponibilizamos ferramentas e técnicas avançadas para assegurar proteção aos cultivos e promover maior produtividade.",
    src: ProtecaoImgSrc,
  },
];

const scrollTo = (selector) => {
  document.querySelector(selector).scrollIntoView({
    behavior: "smooth",
  });
};

export const IntroSection = () => {
  const sliderRef = React.useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      {/* <LoadingWrapper /> */}
      <Social />
      <IntroWrapper>
        <Navbar
          style={{ position: "absolute", left: 0, top: 0, width: "100%" }}
        >
          <Logo />
          <Loader />
          <Links>
            <Links.Link onClick={() => scrollTo("#quem-somos")}>
              Quem somos
            </Links.Link>
            <Links.Link onClick={() => scrollTo("#valores")}>
              Valores
            </Links.Link>
            <Links.Link onClick={() => scrollTo("#solucoes")}>
              Soluções
            </Links.Link>
            <Links.Link onClick={() => scrollTo("#parceiros")}>
              Parceiros
            </Links.Link>
            <Links.Link onClick={() => scrollTo("#curriculum")}>
              Trabalhe conosco
            </Links.Link>
            <Links.Link onClick={() => scrollTo("#localizacao")}>
              Localização
            </Links.Link>
          </Links>
        </Navbar>
        <HamburguerMenuWrapper>
          <Hamburguer isOpen={menuOpen} onToggle={() => setMenuOpen(!menuOpen)}>
            {({ Link }) => {
              return (
                <>
                  <Link
                    onClick={() => {
                      setMenuOpen(false);
                      scrollTo("#quem-somos");
                    }}
                  >
                    Quem Somos
                  </Link>
                  <Link
                    onClick={() => {
                      scrollTo("#valores");
                      setMenuOpen(false);
                    }}
                  >
                    Valores
                  </Link>
                  <Link
                    onClick={() => {
                      scrollTo("#solucoes");
                      setMenuOpen(false);
                    }}
                  >
                    Soluções
                  </Link>
                  <Link
                    onClick={() => {
                      scrollTo("#parceiros");
                      setMenuOpen(false);
                    }}
                  >
                    Parceiros
                  </Link>
                  <Link
                    onClick={() => {
                      scrollTo("#curriculum");
                      setMenuOpen(false);
                    }}>
                    Carreiras
                  </Link>
                  <Link
                    onClick={() => {
                      scrollTo("#localizacao");
                      setMenuOpen(false);
                    }}
                  >
                    Localização
                  </Link>
                </>
              );
            }}
          </Hamburguer>
        </HamburguerMenuWrapper>
        <IntroHero>
          <SectionTitle style={{ textAlign: "center" }}>
            Parceria para alimentar o mundo
          </SectionTitle>
          <IntroDescription>
            Na Maná, criamos soluções. Comercializamos ferramentas para auxiliar
            o agricultor a produzir mais e com mais segurança. Diversificar
            nossas ideias e soluções traz inovação e permite a aplicação de
            novas tecnologias para alimentar um mundo em constante crescimento.
          </IntroDescription>
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                scrollTo("#solucoes");
              }}
            >
              CONHEÇA NOSSAS SOLUÇÕES
            </Button>
          </div>
        </IntroHero>
      </IntroWrapper>
      <WhyManaSection id="quem-somos">
        <div style={{ marginTop: "-6rem" }}>
          <a
            href="#"
            onClick={(evt) => {
              evt.preventDefault();
              setShowVideo(true);
            }}
            onClose={() => setShowVideo(false)}
          >
            <VideoCTAWrapper>
              <VideoCTA />
            </VideoCTAWrapper>
          </a>
        </div>
        <WhyMana>
          <WhyManaTitle>Por que Maná?</WhyManaTitle>
          <WhyManaParagraph>
            De origem hebraica, a palavra Maná tem como significado o alimento
            diário provido ao povo durante uma longa peregrinação no deserto.
            Alimentar o mundo está em nossa essência e você é convidado a ser
            nosso parceiro nessa jornada.
          </WhyManaParagraph>
        </WhyMana>
      </WhyManaSection>

      <ValueSection id="valores">
        <ValueSectionTitle>Nossos valores</ValueSectionTitle>
        <ValuesGrid>
          <ValueItem>
            <ValueItem.Title>Qualidade</ValueItem.Title>
            <ValueItem.Description>
              Alimentar o mundo de forma segura e eficiente, oferencendo
              tecnologia, ferramentas eficientes e seguras ao agricultor.
            </ValueItem.Description>
          </ValueItem>
          <ValueItem>
            <ValueItem.Title>Comprometimento</ValueItem.Title>
            <ValueItem.Description>
              Buscamos a plena satisfação de nossos clientes, fornecedores,
              colaboradores e da sociedade por meio da responsabilidade e
              comprometimento.
            </ValueItem.Description>
          </ValueItem>
          <ValueItem>
            <ValueItem.Title>Parceria</ValueItem.Title>
            <ValueItem.Description>
              Crescer e construir ferramentas e conhecimento para melhorar a
              qualidade de vida e a produção de alimentos.
            </ValueItem.Description>
          </ValueItem>

          <ValueItem>
            <ValueItem.Title>Inovação</ValueItem.Title>
            <ValueItem.Description>
              Utilizar a curiosidade como combustível para a incessante busca
              por conhecimento e evolução.
            </ValueItem.Description>
          </ValueItem>
          <ValueItem>
            <ValueItem.Title>Humildade</ValueItem.Title>
            <ValueItem.Description>
              Nascemos na agricultura e queremos gerar frutos para a
              agricultura.
            </ValueItem.Description>
          </ValueItem>
          <ValueItem>
            <ValueItem.Title>Ética</ValueItem.Title>
            <ValueItem.Description>
              Sempre agir com integridade e fazer o que é certo.
            </ValueItem.Description>
          </ValueItem>
        </ValuesGrid>
      </ValueSection>
      <SolutionsSection id="solucoes">
        <SolutionSlideWrapper>
          <SolutionSlide
            items={solutionsData}
            onChange={(index) => setCurrentSlideIndex(index)}
            ref={sliderRef}
          />
          <SolutionsSectionMenu>
            <SolutionsSectionTitle>
              Soluções para seu agronegócio
            </SolutionsSectionTitle>
            <SolutionsSectionMenuItems>
              {solutionsData.map(({ label }, index) => (
                <Button
                  key={label}
                  filled={false}
                  style={
                    currentSlideIndex === index
                      ? {
                        fontSize: "1rem",
                        borderColor: colors.green,
                        width: "max-content",
                      }
                      : {
                        fontSize: "1rem",
                        paddingLeft: 0,
                        color: "#fff",
                        transition: "padding-left 0.3s",
                        borderColor: "transparent",
                      }
                  }
                  onClick={() => sliderRef.current.goToIndex(index)}
                >
                  {label}
                </Button>
              ))}
            </SolutionsSectionMenuItems>
          </SolutionsSectionMenu>
        </SolutionSlideWrapper>

        <div style={{ position: "relative" }}>
          <CultureWrap>
            <CultureRow />
          </CultureWrap>
        </div>
        <SolutionsSectionFooter />
      </SolutionsSection>
      <SupplierSection id="parceiros">
        <SupplierSectionGrid>
          <SupplierSectionTitle>Quem está ao nosso lado</SupplierSectionTitle>
          <BrandsGrid>
            <SupplierLabel style={{ gridArea: "sojaLabel" }}>
              SOLUÇÕES PARA SOJA
            </SupplierLabel>
            <BrandIcon style={{ gridArea: "sojaBrand1" }} src={PioneerImgSrc} />
            <BrandIcon
              style={{ gridArea: "sojaBrand2" }}
              src={CotrijalImgSrc}
            />
            <BrandIcon style={{ gridArea: "sojaBrand3" }} src={NideraImgSrc} />

            <SupplierLabel style={{ gridArea: "milhoLabel" }}>
              SOLUÇÕES PARA MILHO
            </SupplierLabel>
            <BrandIcon
              style={{ gridArea: "milhoBrand" }}
              src="https://i.imgur.com/wZTmGCr.png"
              src={MorganImgSrc}
            />
            <SupplierLabel style={{ gridArea: "defesaLabel" }}>
              SOLUÇÕES PARA DEFESA E NUTRIÇÃO
            </SupplierLabel>
            <BrandIcon style={{ gridArea: "defesa1" }} src={CortevaImgSrc} />
            <BrandIcon style={{ gridArea: "defesa2" }} src={MaximusImgSrc} />
          </BrandsGrid>
        </SupplierSectionGrid>
      </SupplierSection>
      <CurriculumSection id="curriculum">
        <CurriculumSectionGrid>
          <CurriculumSectionTitle>Quer fazer parte da nossa equipe?</CurriculumSectionTitle>
          <CurriculumForm />
        </CurriculumSectionGrid>
      </CurriculumSection>
      <MapSection id="localizacao">
        <AddressInfoMobile />
        <DiagonalClip>
          <ClipMap>
            <Map />
            <AddressMapIconWrapper>
              <AddressInfo />
            </AddressMapIconWrapper>
          </ClipMap>
        </DiagonalClip>
      </MapSection>
      <Modal open={showVideo} onClose={() => setShowVideo(false)} />
    </div>
  );
};

// export default Home;
