import styled from "styled-components";
import SolutionOverlaySrc from "../../img/solution-overlay.svg";
import SolutionOverlayGradientSrc from "../../img/solution-overlay-gradient.svg";

export const Background = styled.div`
  position: relative;
  width: 463px;
  height: 421px;
  transition: all 0.3s;

  @media (max-width: 600px) {
    width: 90%;
  }
`;
export const BackgroundImage = styled.img`
  background-color: ${(props) => props.theme.green};
  display: block;

  width: 100%;
  height: 100%;
  transition: all 0.3s;
  clip-path: url("#clip-path");
  object-fit: cover;
  object-position: center;
  @media (min-width: 780px) {
  }
`;

export const Overlay = styled.img.attrs({ src: SolutionOverlaySrc })`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;

  transition: opacity 0.3s;
  opacity: 0;
  @media (max-width: 780px) {
    /* transform: scale(0.7); */
  }
`;

export const Description = styled.p`
  color: #fff;
  font-size: 1rem;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 320px;
  line-height: 1.3rem;
  transform: translate3d(-62%, 0px, 0px);
  opacity: 0;
  margin-bottom: 0;
  transition: margin 0.3s, opacity 0.3s;
`;

export const OverlayGradient = styled(Overlay).attrs({
  src: SolutionOverlayGradientSrc,
})``;

export const SlideLabelMobile = styled.h6`
  font-size: 1.825rem;
  line-height: 2rem;
  font-family: "FuturaBoldItalic";
  margin-bottom: 1rem;

  color: #fff;

  display: none;
  @media (max-width: 900px) {
    display: block;
  }
`;

export const SliderContainer = styled.div`
  .glide__slides {
    padding-bottom: 1rem;
    align-items: flex-end;
    min-height: 610px;
    @media (max-width: 900px) {
      margin-top: 10rem;
    }
  }

  .glide__slide {
    opacity: 0.45;

    &--active {
      opacity: 1;
      z-index: 1000;
      transform: translate3d(-60px, 0, 0);
      ${BackgroundImage} {
        width: 600px;
        @media (max-width: 600px) {
          width: 95%;
        }
      }

      ${Background} {
        width: 600px;
        height: 546px;
      }

      ${Overlay} {
        opacity: 1;
        @media (max-width: 600px) {
          width: 95%;
          height: 100%;
        }
      }

      ${Description} {
        opacity: 1;
        margin-bottom: 3rem;
      }
    }

    transition: opacity 0.8s, transform 0.3s;
  }
`;
