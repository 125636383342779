import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 2rem;
  align-items: flex-end;
`;

export const LinkAnchor = styled.a`
  color: #fff;
  padding: 0 1rem 0 1rem;
  cursor: pointer;
  font-family: "FuturaMedium";
`;

export const LinksList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  @media (max-width: 750px) {
    display: none;
  }
`;
