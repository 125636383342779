import React from "react";
import styled from "styled-components";

import VideoSplashSrc from "../../img/video-cta.png";
import VideoSplashBorderSrc from "../../img/border-video.svg";
import PlayButtonSrc from "../../img/play-button.svg";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
`;
const Image = styled.img`
  max-width: 100%;
`;

const PlayButton = styled.a`
  display: block;
  width: 5.1875rem;
  height: 5.1875rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate3d(-50%, -50%, 0);
  img {
    margin-left: 10px;
  }
`;

const ImageBorder = styled.img`
  position: absolute;
  left: 0.5rem;
  top: 0.35rem;
  max-width: 100%;
`;

function VideoCTA() {
  return (
    <Container>
      <PlayButton>
        <img src={PlayButtonSrc} alt="Video institucional" />
      </PlayButton>
      <Image src={VideoSplashSrc} />
      <ImageBorder src={VideoSplashBorderSrc} />
    </Container>
  );
}

export default VideoCTA;
