import React from "react";
import logo from "../../img/logo.svg";
import { Container, LinkAnchor, LinksList } from "./styles";

export const Logo = () => {
  return <img src={logo} />;
};

const Links = ({ children }) => {
  return <LinksList>{children}</LinksList>;
};

Links.Link = ({ children, ...linkProps }) => {
  return (
    <li>
      <LinkAnchor {...linkProps}>{children}</LinkAnchor>
    </li>
  );
};

function Navbar({ children, style }) {
  return <Container style={style}>{children}</Container>;
}

export { Navbar as default, Links };
