import React, { useEffect } from "react";
import "./style.css";
import { Container, Overlay, FadeIn, Links, Link } from "./styles";

function Hamburguer({ isOpen, onToggle, children }) {
  //   useEffect(() => {
  //     if (isOpen) {
  //       document.querySelector("html").classList.add("no-scroll");
  //       document.querySelector("body").classList.add("no-scroll");
  //     } else {
  //       document.querySelector("html").classList.remove("no-scroll");
  //       document.querySelector("body").classList.remove("no-scroll");
  //     }
  //   }, [isOpen]);
  return (
    <Container>
      {isOpen && (
        <FadeIn>
          <Overlay>
            <Links>{children({ Link })}</Links>
          </Overlay>
        </FadeIn>
      )}
      <button
        onClick={() => onToggle()}
        class={`hamburger hamburger--spin ${isOpen ? "is-active" : ""}`}
        type="button"
        // style={{ position: isOpen ? "fixed" : "absolute" }}
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </Container>
  );
}

export default Hamburguer;
