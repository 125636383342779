import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.white};
`;

export const ContainerForm = styled.div`
    // display:flex;
    // flex-direction: column;
    margin-top: 20px;
    align-items: center; 
`;

export const CurriculumBorder = styled.div`
    width: 100%;
    border: none;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    transform: skewX(-20deg);
    margin: 9px;
    &:after {
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: inherit;
        border: 1px solid ${(props) => props.theme.green};
        
    }
`;

export const CurriculumInput = styled.input`
    spacing: 5px;
    padding: 5px;
    flex: 1;
	margin: 5px;
    width: 98%;
    border: none;
    .error {
        border: 1px solid ${(props) => props.theme.red};
    }
`;

export const CurriculumButton = styled.input`
    border: none;
    background-color: ${(props) => props.theme.green};
    flex: 1;
	margin: 5px;
    padding: 10px;
    width: 200px;
    color: ${(props) => props.theme.white};
`;

export const CurriculumText = styled.div`
    margin: 3px;
    text-align: justify;
`;

export const CurriculumTextBold = styled.span`
    font-weight: 700;
`;