import React from "react";
import styled from "styled-components";

import LeafSrc from "../../img/leaf.svg";

const LeafIcon = styled.img`
  position: absolute;
  left: 0;
  top: 0;
`;

const Container = styled.div`
  padding-left: 2rem;
  position: relative;
  display: flex;
  flex-flow: column wrap;
`;

const ValueItem = ({ children }) => {
  return (
    <Container>
      <LeafIcon src={LeafSrc} />
      {children}
    </Container>
  );
};

ValueItem.Title = styled.h6`
  font-family: "FuturaMedium";
  color: #fff;
  margin-bottom: 1.14285rem;
  font-size: 1.25rem;
`;

ValueItem.Description = styled.p`
  font-family: "FuturaBook";
  color: rgba(255, 255, 255, 0.75);
`;

export default ValueItem;
