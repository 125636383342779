import React from "react";

import {
  Container,
  Background,
  Adorn,
  Logo,
  Title,
  Description,
  InfoWrapper,
  MobileBackground,
} from "./styles";

export const AddressInfoMobile = () => {
  return (
    <MobileBackground>
      <div>
        <Logo />
        <Title>COMERCIAL AGRÍCOLA MANÁ</Title>
        <Description>
          RS 130 KM 76, Nº1420 Barra do Forqueta Arroio do Meio, RS 51 3716 3702
        </Description>
      </div>
    </MobileBackground>
  );
};

function AddressInfo() {
  return (
    <Container>
      <Background>
        <InfoWrapper>
          <Logo />
          <Title>COMERCIAL AGRÍCOLA MANÁ</Title>
          <Description>
            RS 130 KM 76, Nº1420 Barra do Forqueta Arroio do Meio, RS 51 3716
            3702
          </Description>
        </InfoWrapper>
      </Background>
      <Adorn />
      <svg style={{ width: 0, height: 0 }} class="svg">
        <clipPath id="clip-address" clipPathUnits="objectBoundingBox">
          <path d="M0.66,0 H0.002 L0,1 H0.934 L0.99,0.83 C0.99,0.83,1,0.792,1,0.769 C0.999,0.75,0.99,0.725,0.99,0.725 L0.723,0.057 C0.723,0.057,0.715,0.028,0.693,0.012 C0.679,0.001,0.66,0,0.66,0"></path>
        </clipPath>
      </svg>
    </Container>
  );
}

export default AddressInfo;
