import React, { useState, useEffect, useCallback } from "react";
import useWindowEvent from "../../hooks/useWindowEvent";
import Button from "../Button";
import colors from "../../config/colors";
import { Container, FadeIn, FixedContainer } from "./styles";

function Modal({ open = false, onClose = null }) {
  const [windowWidth, setWindowWidth] = useState(0);
  const setModalWidth = useCallback((e) => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(setModalWidth, setModalWidth);
  useWindowEvent("resize", setModalWidth);

  const videoWidth = Math.min(windowWidth * 0.8, 960);
  const videoHeight = Math.min(
    videoWidth * 0.5625,
    (typeof window !== "undefined" ? window.innerHeight : 0) * 0.8
  );

  return (
    open && (
      <FixedContainer>
        <FadeIn duration="0.4s">
          <Container
            onClick={() => {
              if (onClose) onClose();
            }}
          >
            <iframe
              src={`https://www.youtube.com/embed/clQmFYbBFrM?loop=1&modestbranding=1&autoplay=1`}
              width={videoWidth}
              height={videoHeight}
              frameborder="0"
              allowfullscreen=""
            />
            <div
              style={{
                position: "absolute",
                top: "2rem",
                right: "4rem",
              }}
            >
              <Button color="#fff" textColor={colors.greenLight}>
                FECHAR
              </Button>
            </div>
          </Container>
        </FadeIn>
      </FixedContainer>
    )
  );
}

export default Modal;
