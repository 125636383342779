import React from "react";

import PhoneSrc from "../../img/social/whatsapp.svg";
import FacebookSrc from "../../img/facebook.svg";
import InstagramSrc from "../../img/social/instagram.svg";

import { Container, Icon, Separator, Wrapper, IconWrapper } from "./styles";

function Social() {
  return (
    <Container>
      <Wrapper>
        <IconWrapper target="_facebook" href="https://fb.com/agricolamana">
          <Icon style={{ height: "1rem" }} src={FacebookSrc} />
        </IconWrapper>
        <Separator />
        <IconWrapper
          target="_instagram"
          href="https://www.instagram.com/agricola_mana/"
        >
          <Icon style={{ height: "1rem" }} src={InstagramSrc} />
        </IconWrapper>
        <Separator />

        <IconWrapper
          target="_linkedin"
          href="https://api.whatsapp.com/send?phone=5551997916773"
        >
          <Icon style={{ height: "1.2rem" }} src={PhoneSrc} />
        </IconWrapper>
      </Wrapper>
    </Container>
  );
}

export default Social;
