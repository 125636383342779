import React, { useRef, useEffect } from "react";
import "leaflet/dist/leaflet.css";
// import L from "leaflet/dist/leaflet.js";
import MarkerIcon from "../../img/map-marker/icon.png";
import MarkerIconShadow from "../../img/map-marker/shadow.png";
// import { Container } from './styles';

const position = [-29.41012, -51.96042];
function Map() {
  const mapRef = useRef(null);
  useEffect(() => {
    let mymap = null;
    if (mapRef.current) {
      const L = require("leaflet/dist/leaflet.js");

      var myIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [98, 103],
        iconAnchor: [44, 94],
        // popupAnchor: [-3, -76],
        shadowUrl: MarkerIconShadow,
        shadowSize: [70, 83],
        shadowAnchor: [60, 78],
      });

      mymap = L.map("map-id").setView(position, 18);
      L.marker(position, { icon: myIcon }).addTo(mymap);
      console.log({ mymap });
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      }).addTo(mymap);
      window.removeMap = () => mymap.remove();
    }

    return () => {
      if (mymap) {
        mymap.remove();
      }
    };
  }, [mapRef.current]);
  return <div id="map-id" ref={mapRef} style={{ height: "100%" }} />;
}

export default Map;
