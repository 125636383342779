import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  border: 1px solid #ffffffbb;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.green}dd;
`;

export const IconWrapper = styled.a`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  /* width: 20px; */
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ffffffbb;
`;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 3rem;
  transform: translate3d(-50%, 0, 0);
  z-index: 200;
  display: flex;

  @media (max-width: 850px) {
    top: auto;
    bottom: 1rem;
    left: 50%;

    ${Wrapper} {
      flex-flow: row wrap;
    }

    ${Separator} {
      width: 1px;
      height: 100%;
    }

    ${IconWrapper} {
      min-width: 3rem;
    }
  }
`;
